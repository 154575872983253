import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styles from "./socialMedia.module.scss"
import Header from "../components/header"
import Footer from "../components/footer"
import globalStyles from "../services/globalStyles.module.scss"
import xpsLogo from "../images/index/logo.svg"
import linkedinIcon from "../images/common/linkedin_white.svg"
import questionsIcon from "../images/common/questions.svg"
import facebookIcon from "../images/common/facebook_white.svg"
import twitterIcon from "../images/common/twitter_white.svg"
import youtubeIcon from "../images/common/youtube_white.svg"
import instagramIcon from "../images/common/instagram_white.svg"
import podcastIcon from "../images/common/podcast.svg"
import downloadIcon from "../images/common/downloadalt.svg"
import newspaperIcon from "../images/common/news-paper.svg"
import playIcon from "../images/common/play_icon.svg"
import YouTube from "react-youtube"

const Link = ({ id, icon, label, description, href, target, className }) => {
  return (
    <a
      className={[styles.linkWrapper, className].join(" ")}
      id={id}
      href={href}
      target={target}
    >
      <img className={styles.linkIcon} src={icon} />
      <div className={styles.linkTexts}>
        <p className={styles.linkLabel}>{label}</p>
        <p className={styles.linkDescription}>{description}</p>
      </div>
    </a>
  )
}

const Expandable = ({ id, icon, label, description, className }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [youtubeRef, setYoutubeRef] = useState(null)
  const opts = {
    height: "433",
    width: "769",
  }
  const onReady = (e) => {
    setYoutubeRef(e.target)
    if (isExpanded) {
      e.target.playVideo()
    }
  }
  return (
    <div>
      <div
        className={[styles.linkWrapper, className].join(" ")}
        id={id}
        onClick={() =>
          setIsExpanded((prev) => {
            youtubeRef && prev
              ? youtubeRef.pauseVideo()
              : youtubeRef.playVideo()
            return !prev
          })
        }
      >
        <img className={styles.linkIcon} src={icon} />
        <div className={styles.linkTexts}>
          <p className={styles.linkLabel}>{label}</p>
          <p className={styles.linkDescription}>{description}</p>
        </div>
      </div>
      <div
        className={[
          styles.expandableContentWrapper,
          isExpanded && styles.expanded,
        ].join(" ")}
      >
        <div className={styles.videoWrapper}>
          <YouTube videoId="xUJ3pvqigrU" opts={opts} onReady={onReady} />
        </div>
      </div>
    </div>
  )
}

const SocialMedia = (props) => {
  const _ = props.data?.texts?._
  const languageCode =
    props.pageContext && props.pageContext.lang ? props.pageContext.lang : "en"
  return (
    <Layout
      header={
        <Header
          num={1}
          translations={_}
          headerFullYOffset={500}
          mobileHeaderFullYOffset={40}
          fixed
          languageCode={languageCode}
        />
      }
      translations={_}
      languageCode={languageCode}
    >
      <img className={styles.xpsLogo} src={xpsLogo} alt="XPS logo" />
      <div className={styles.linkTreeWrapper}>
        <Expandable
          icon={playIcon}
          label="XPS IN 30 SEC"
          description="Watch XPS Overview"
          className={styles.watchXps}
        />
        <Link
          href={`https://sidelinesports.com/${languageCode === 'cs' ? 'cs/' : ''}blog`}
          target="_blank"
          icon={newspaperIcon}
          label="BLOG"
          description="Get the News"
          className={styles.blog}
        />
        <Link
          href="/"
          icon={downloadIcon}
          label="WEBSITE"
          description="Get a Free Trial"
          className={styles.website}
        />
        <Link
          href="https://www.instagram.com/xpsnetwork/"
          target="_blank"
          icon={instagramIcon}
          label="INSTAGRAM"
          description="Follow us"
          className={styles.instagram}
        />
        <Link
          href="https://stories.sidelinesports.com"
          target="_blank"
          icon={youtubeIcon}
          label="YOUTUBE"
          description="Subscribe"
          className={styles.youtube}
        />
        <Link
          href="https://twitter.com/xpsnetwork"
          target="_blank"
          icon={twitterIcon}
          label="TWITTER"
          description="Follow us"
          className={styles.twitter}
        />
        <Link
          href="https://www.facebook.com/sidelinesports/"
          target="_blank"
          icon={facebookIcon}
          label="FACEBOOK"
          description="Follow us"
          className={styles.facebook}
        />
        <Link
          href="https://xps.sidelinesports.com/en/tutorials"
          target="_blank"
          icon={questionsIcon}
          label="TUTORIALS"
          description="Learn about us"
          className={styles.tutorials}
        />
        <Link
          href="https://www.linkedin.com/company/sideline-sports"
          target="_blank"
          icon={linkedinIcon}
          label="LINKEDIN"
          description="Follow us"
          className={styles.linkedin}
        />
        <Link
          href="https://sidelinesports.com/blog/xps-hub-the-story-behind-the-coach"
          target="_blank"
          icon={podcastIcon}
          label="PODCAST"
          description="Listen to us"
          className={styles.podcast}
        />
      </div>
      <Header
        num={2}
        className={globalStyles.hideOnMobile}
        translations={_}
        headerFullYOffset={500}
        boxShadow
        languageCode={languageCode}
      />
      <Footer translations={_} languageCode={languageCode} />
    </Layout>
  )
}

export default SocialMedia

export const query = graphql`
  query LinksTree($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...ProductPresentationTranslations
        time_to_get_started
        enter_your_email
        start_free_trial
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
  }
`
